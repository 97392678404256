<template>
  <div class='container'>
    <div class="bg">
      <img :src="require(`@/assets/login/logo${gxOriginBool?'-gx':xyOriginBool?'-xy':qtOriginBool?'-qt':gdOriginBool?'-gd':''}.png`)" alt="" style="position: absolute;top: 15px;left: 19.4667vw;width: 61.3333vw;height: 16vw;">
    </div>
    <div class="content">
      <div class="login">
        <h2 >校管理登录</h2>
        <p style="color: #868484;font-size: 3.2vw;">一站式自助心理工作管理、服务平台</p>
        <div class="login-content">
          <div class="tabs">
            <div :class="['tab',activeIndex===0?'active':'']" @click="activeIndex=0">账号密码登录</div>
            <div :class="['tab',activeIndex===1?'active':'']" @click="activeIndex=1">手机号登陆</div>
          </div>
          <div class="option" v-if="activeIndex===0">
            <div class="input">
              <img class="icon" src="@/assets/login/user.png" alt="">
              <input type="text" v-model="loginForm.username" placeholder="请输入账号">
            </div>
            <div  class="input">
              <img class="icon" src="@/assets/login/clock.png" alt="">
              <input type="text" v-model="loginForm.password" placeholder="请输入密码">
            </div>
          </div>
          <div class="option" v-else>
            <div class="input">
              <img class="icon" src="@/assets/login/phone.png" alt="">
              <input type="text" v-model="loginForm1.phone" placeholder="请输入手机号">
            </div>
            <div  class="input">
              <img class="icon" src="@/assets/login/clock.png" alt="">
              <input style="padding-right: 35%;" type="text" v-model="loginForm1.vcode" placeholder="请输入验证码">
              <button :disabled="btnText !== '发送验证码'" @click="sendCode">{{btnText}}</button>
            </div>
          </div>
          <p style="margin: 4vw 0 5.3333vw 0;font-size: 2.9333vw;color:#666">登录即代表已阅读并同意 <b style="color: #596DF8;font-weight: 550;">《服务协议、隐私政策》</b></p>
          <button class="btn" @click="login">登录</button>
          <p style="color:#596DF8;margin-top: 5.3333vw;" @click="isDialog=true">免费注册账号</p>
        </div>
      </div>
    </div>
    <div class="message">
      <img :src="require(`@/assets/login/chat${gxOriginBool?'-gx':xyOriginBool?'-xy':gdOriginBool?'-gd':''}.jpg`)" alt="" style="width: 34.6667vw;height: 34.6667vw;margin: 5.0667vw 0 1.3333vw 0;">
      <p style="font-size: 2.9333vw;color: #7a7979;">扫码关注公众号</p>
      <p style="font-size: 2.9333vw;color: #7a7979;">{{`${gxOriginBool?'':xyOriginBool?'心悦心理成长服务中心':gdOriginBool?'“粤心研青少年心理成长服务中心”':'“青少年心理成长服务中心”'}`}}</p>
      <div class="detail" v-if="gxOriginBool">
        <div style="display: flex;justify-content: center;">
          <p>联系地址： </p>
          <p style="flex: 1;text-align: left;">南宁市青秀区建政路37号广西教育学院北校区图书馆二楼</p>
        </div>
        <p>联系方式：覃老师18977086612</p>
      </div>
      <div class="detail" v-else-if="gdOriginBool">
        <div style="display: flex;justify-content: center;">
          <p>研究会地址： </p>
          <p style="flex: 1;text-align: left;">广州市越秀区麓景路7号老干大厦五楼 (小北地铁站)</p>
        </div>
        <p>电子邮箱：gdapsy@126.com</p>
        <p>电话： 020-31703170</p>
      </div>
      <div class="detail" v-else-if="qtOriginBool">
        <div style="display: flex;justify-content: center;">
          <p>公司地址：</p>
          <p style="flex: 1;text-align: left;">中山市东区中山四路88号尚峰金融商务中心五座18层 </p>
        </div>
        <p>商务合作：281135811@qq.com</p>
        <p>电话：020-83830097</p>
      </div>
      <div class="detail" v-else-if="xyOriginBool">
        <div style="display: flex;justify-content: center;">
          <p>公司地址：</p>
          <p style="flex: 1;text-align: left;">广州市黄埔区彩频路11号广东软件科学园F栋701 </p>
        </div>
        <p>商务合作: support@uelink.com.cn</p>
        <p>电话: 020-31703170</p>
      </div>
      <div class="detail" v-else>
        <div style="display: flex;justify-content: center;">
          <p>公司地址：</p>
          <p style="flex: 1;text-align: left;">广州市黄埔区彩频路11号广东软件科学园F栋701 </p>
        </div>
        <p>商务合作: support@uelink.com.cn</p>
        <p>电话: 020-31703170</p>
      </div>
    </div>
    <van-dialog v-model="isDialog" :showConfirmButton="false">
      <div class="dialog">
        <h2>申请试用</h2>
        <img class="close" src="@/assets/login/colse.png" alt="" @click="isDialog=false">
        <p>欢迎使用青少年心理成长服务平台，为学校和家长提供一站式智能心理筛查和管理！</p>
        <p>请联系 {{gxOriginBool?'覃老师 18977086612':gdOriginBool?'020-83830097':'020-31703170'}} ，为你免费提供试用账号。 或添加下方客服微信交流：</p>
        <img :src="require(`@/assets/login/chat${gxOriginBool?'-gx':xyOriginBool?'-xy':gdOriginBool?'-gd':''}.jpg`)" alt="" style="width: 34.6667vw;height: 34.6667vw;">
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {getVcode} from '@/api/user.js'
export default {
  data(){
    return{
      loginForm: {
          username: '',
          password: ''
      },
      loginForm1: {
          phone: '',
          vcode: ''
      },
      btnText: '发送验证码',
      activeIndex:0,
      isDialog:false,
      time:0,
      gdOriginBool:false,
      qtOriginBool:false,
      xyOriginBool:false,
      gxOriginBool:false
    }
  },
  created(){
    const gxOriginBool = this.$store.state.originBool
     if (gxOriginBool) {
       console.log('广西')
       this.gxOriginBool = gxOriginBool
     }
     const xyOriginBool = this.$store.state.xyOriginBool
     if (xyOriginBool) {
       console.log('心悦成长')
       this.xyOriginBool = xyOriginBool
     }
     const qtOriginBool = this.$store.state.qtOriginBool
     if (qtOriginBool) {
       console.log('全通教育')
       this.qtOriginBool = qtOriginBool
     }
     const gdOriginBool = this.$store.state.gdOriginBool
     if (gdOriginBool) {
       console.log('广东省心理研究会')
       this.gxOriginBool = gdOriginBool
     }

    //  this.xyOriginBool=true
  },
  methods:{
    async login(){
      const loginForm = JSON.parse(JSON.stringify(this.activeIndex===0?this.loginForm:this.loginForm1))
      console.log(loginForm);
      
      if(this.activeIndex===0){
        if (!loginForm.username?.trim()) {
          this.$toast.fail('请输入账号')
          return
        }
        if (!loginForm.password?.trim()) {
            this.$toast.fail('请输入密码')
            return
        }
        await this.$store.dispatch('login',loginForm)
        this.$router.push('/')
      }else{
        if (!loginForm.phone?.trim()) {
          this.$toast.fail('请输入手机号码')
          return
        }
        if (!(/^[1][2,3,4,5,6,7,8][0-9]{9}$/.test(loginForm.phone))) {
            this.$toast.fail('请输入正确的手机号')
            return
        }
        if (!loginForm.vcode?.trim()) {
            this.$toast('请输入验证码')
            return
        }
        await this.$store.dispatch('loginByPhone',loginForm)
        this.$router.push('/')
      }
    },
    async sendCode(){
      if(this.btnText!=='发送验证码'){
        return
      }
      if (!this.loginForm1.phone) {
          this.$toast.fail('请先输入手机号')
          return
      }
      if (!(/^[1][2,3,4,5,6,7,8][0-9]{9}$/.test(this.loginForm1.phone))) {
          this.$toast.fail('请输入正确的手机号')
          return
      }
      const data = {
          phone: this.loginForm1.phone,
          type: 2
      }
      await getVcode(data)
      this.$toast.success('获取成功')
      this.time = 60
      this.timer()
    },
    timer () {
        if (this.time > 0) {
            this.time--
            this.btnText = this.time + '秒'
            setTimeout(this.timer, 1000)
        } else {
            this.time = 0
            this.btnText = '发送验证码'
        }
    },
  }
}
</script>
<style lang='less' scoped>
.container{
  position: relative;
  padding-bottom: 8vw;
  min-height: 100%;
  background-color: #f1f0f0;
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70vh;
    background: url('../../assets/login/login-bg.png') top center /100% 70vh no-repeat;
  }
  .content{
    position: relative;
    left: 4%;
    top: 30vh;
    // left: 4vw;
    width: 92%;
    // height: 70vh;
    background-color: #fff;
    border-radius: 2.1333vw 2.1333vw 0 0;
    .login{
      padding-bottom: 5.3333vw;
      text-align: center;
      border-radius: 2.6667vw;
      box-shadow: 0vw 0vw 2.1333vw 0vw rgba(0,0,0,0.25);
      h2{
        padding: 5.3333vw 0 2.1333vw 0;
        color: #545454;
        font-size: 4.8vw;
      }
      .login-content{
        padding: 0 5.3333vw;
        .tabs{
          margin-top: 8vw;
          display: flex;
          align-items: center;
          .tab{
            flex: 1;
            font-size: 4vw;
            padding-bottom: 2.4vw;
            border-bottom: .2667vw solid #EDEDED;
          }
          .tab.active{
            color: #596DF8;
            font-weight: 550;
            border-bottom: .2667vw solid #596DF8;

          }
        }
        .option{
          margin-top: 8vw;
          .input{
            position: relative;
            &:last-child{
              margin-top: 5.3333vw;
            }
            button{
              width: 30%;
              position: absolute;
              top: 50%;
              right: 1.6vw;
              padding: 0 3.2vw;
              height: 9.6vw;
              background: #596DF8;
              border-radius: 1.3333vw;
              color: #fff;
              transform: translateY(-50%);
            }
            .icon{
              position: absolute;
              top: 50%;
              left: 2.6667vw;
              width: 5.3333vw;
              height: 5.3333vw;
              transform: translateY(-50%);
              z-index: 2;
            }
            input{
             position: relative;
             padding: 0 4vw 0 10.6667vw;
             width: 100%;
             height: 12.2667vw;
             background: #F6F6F6;
             border-radius: 2.6667vw;
             &::placeholder{
               color: #666;
             }
            }
          }
        }
      }
      .btn{
        width: 100%;
        color: #fff;
        font-size: 4vw;
        height: 12.2667vw;
        background: #596DF8;
        border-radius: 2.6667vw;
      }
    }
  }
  .message{
    margin-top: 30vh;
    text-align: center;
    .detail{
      margin:4.8vw 12vw 0 12vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 2.9333vw;
      color: #7a7979;
      p{
        line-height: 5.3333vw;
      }
    }
  }
  .dialog{
    position: relative;
    padding: 4.5333vw 5.8667vw 8vw 5.8667vw;
    text-align: center;
    .close{
      position: absolute;
      top: 4vw;
      right: 4vw;
      width: 5.3333vw;
      height: 5.3333vw;
    }
    p{
      line-height: 4.8vw;
      text-align: left;
      font-size: 3.4667vw;
      color: #7a7979;
      margin-bottom: 5.3333vw;
      &:nth-of-type(1){
        margin-top: 8vw;
      }
    }
  }
}
</style>